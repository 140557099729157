




























import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch, PropSync } from 'vue-property-decorator';
import { UserGroupService } from '@/services/user-group-service';
// import { any } from 'core-js/fn/promise';

@Component({
  components: {

  }
})
export default class chatBook extends Vue {

  public  data:any = []
  public copyTreeList:any = [];
  public filterText:any = ''
  public defaultProps:any = {
      children: 'children',
      label: 'partyName'
  }
  public loading: boolean = false
  @Watch('filterText', { deep: true })
  handleWatch(val:any) {
    console.log('监听上面数组的内容');
    let tres = (this.$refs.tree as any)
    tres.filter(val);
  }


  @Inject(UserGroupService) private userGroupService!: UserGroupService;

  public async created(): Promise<void> {
    this.getTree()
  }

  public async getTree(){
    let trees = await this.userGroupService.deptTree();
    this.data = trees;
  }
  filterNode(value:any, data:any) {
      if (!value) return true;
      return data.partyName.indexOf(value) !== -1;
  }
  
  //同步到企业微信
  public async chatPropSync(){
    let res = await this.userGroupService.syncOrg();
    if(res !== undefined ){
        this.$message({
            message: '同步到企微成功',
            type: 'success'
        });   
    }     
          
  }
  handleDragStart() {
    this.copyTreeList = JSON.parse(
      JSON.stringify(this.data)
    );
  }
  handleDragEnd(draggingNode:any, dropNode:any, dropType:any, ev:any) {
        console.log('tree drag end: ', dropNode && dropNode.label, dropType);
  }
  public async handleDrop(draggingNode:any, dropNode:any, dropType:any, ev:any) {
        console.log('tree drop: ', dropNode, dropType);
            var paramData :any= [];
            // 当拖拽类型不为inner,说明只是同级或者跨级排序，只需要寻找目标节点的父ID，获取其对象以及所有的子节点，并为子节点设置当前对象的ID为父ID即可
            // 当拖拽类型为inner，说明拖拽节点成为了目标节点的子节点，只需要获取目标节点对象即可
            var data = dropType != "inner" ? dropNode.parent.data : dropNode.data;
            var nodeData = dropNode.level == 1 && dropType != "inner" ? data : data.children;
            // 设置父ID,当level为1说明在第一级，pid为0
            nodeData.forEach((element:any) => {
              element.pid = dropNode.level == 1 ? 0 : data.id;
            });
            nodeData.forEach((element:any, i:any) => {
              var collection = {
                id: element.id,
                parentId: element.pid,
                partyName:element.partyName
              };
              paramData.push(collection);
            });

            this.loading = true;
            let param :any = paramData[0]
            
           
        this.$confirm(
          `是否将节点移动到${dropNode.label}${
            dropType == "before" ? "之前" : dropType == "after" ? "之后" : "下"
          }?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        )
        .then(() => {
       	 // 成功之后调用接口
          this.userGroupService.wxDeptRelation(param).then(res => {
              this.loading = false;         
              this.$message({
                  message: '修改部门关系成功',
                  type: 'success'
              });          
          });
         
        })
        .catch(() => {
          this.data= this.copyTreeList;
          this.loading = false;  
          this.$message({
            type: "info",
            message: "取消拖动"
          });
        });

  }
  allowDrop(draggingNode:any, dropNode:any, type:any) {
        return true;
        // 不允许文件中移入结点
        // if(type == 'inner' && dropNode.data.nodeType != '1') {
        // 	return false;
        // }else {
        // 	return true;
        // }
  }
}
